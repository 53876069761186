import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import SVGLogo from "../svg/logo"

const Header = ({ siteTitle }) => (
  <header className="e_header e_food-background bg-dark py-2 sticky-top">
    <div className="container">
      <nav className="navbar navbar-dark navbar-expand-sm container px-0">
        <h1 className="my-0">
          <Link className="navbar-brand" to="/">
            <SVGLogo className="mr-3" />
            <span className="e_header--brand">{siteTitle}</span>
          </Link>
        </h1>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link text-white" to="/create">
              create
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
